/* eslint import/extensions: "off" */

import Button from '@components/Button';
import Container from '@components/Container';
import StyledFormModal from '@components/FormModal';
import Grid from '@components/Grid';
import Hero from '@components/Hero';
import HowToBuy from '@components/HowToBuy';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
    IPFS_ENDPOINT,
    isReleased,
    SHOW_BUY_PAGE,
    SHOW_HEADER,
    WAX_SIGN_IN
} from '@utils/globals';
import RES from '@utils/resources';
import ROUTES from '@utils/routes';
import STRINGS from '@utils/strings';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const InfoContainer = styled.div(({ theme }) => ({
    minHeight: SHOW_HEADER ? `calc(100vh - ${theme.size.headerHeight})` : '100vh',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto'
}));

const Headline = styled.h1(({ theme }) => ({
    ...theme.typography.h1,
    ...theme.typography.forum,
    textAlign: 'center',
    margin: `${theme.spacing.xs} ${theme.spacing.xxs} `,
    color: theme.colors.common.background,
    [theme.mediaQuery.desktopUp]: {
        margin: ` 0 ${theme.spacing.xs} 0`
    }
}));

const UpcomingHeadline = styled.h1(({ theme }) => ({
    ...theme.typography.forum,
    color: theme.colors.primaryLight,
    lineHeight: '130%',
    textAlign: 'left',
    maxWidth: '60ch',
    letterSpacing: 2.8,
    [theme.mediaQuery.largeMobileUp]: {
        textAlign: 'center'
    }
}));

const UpcomingSubtext = styled.p(({ theme }) => ({
    color: theme.colors.primaryLight,
    lineHeight: '180%',
    textAlign: 'left',
    maxWidth: '85ch',
    fontSize: '1.125rem',
    fontWeight: 300,
    [theme.mediaQuery.largeMobileUp]: {
        textAlign: 'center'
    }
}));

const BlogPost = styled.a(({ theme }) => ({
    color: theme.colors.primary.dark,
    backgroundColor: theme.colors.primary.main,
    padding: `${theme.spacing.xxs} ${theme.spacing.s}`,
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1.125rem',
    letterSpacing: 3,
    opacity: 1,
    margin: `${theme.spacing.m}`,
    '&:hover': {
        background: `${theme.colors.primary.light} !important`,
        color: theme.colors.primary.main
    }
}));

const Image = styled.img(({ theme }) => ({
    height: '50vh',
    maxHeight: '600px',
    objectFit: 'contain',
    [theme.mediaQuery.mobileOnly]: {
        maxHeight: '300px'
    }
}));

const PackDescription = styled.p(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.primary.main,
    fontWeight: 'bold'
}));

const PackDetailsTitle = styled.h5(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.colors.primary.main,
    fontWeight: 'bold',
    marginBottom: theme.spacing.xs
}));

const PackDetailsList = styled.ul(({ theme }) => ({
    ...theme.typography.p,
    marginTop: theme.spacing.xxs,
    color: theme.colors.common.text,
    listStyle: 'none',
    textAlign: 'left',
    padding: 0,
    [theme.mediaQuery.tabletUp]: {
        width: '100%'
    }
}));

const PackDetailItem = styled.li(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottom: `thin solid ${theme.colors.primary.main}69`,
    padding: `${theme.spacing.xxs} 0`
}));

function Home() {
    const theme = useTheme();
    const history = useNavigate();

    const [ showHelpModal, setShowHelpModal ] = useState(false);
    const [ showFormModal, setShowFormModal ] = useState(false);

    const CustomGrid = {
        gap: theme.spacing.l,
        rowGap: theme.spacing.xxl,
        img: {
            width: 270,
            height: 270,
            borderRadius: '50%',
            objectFit: 'cover'
        },
        [theme.mediaQuery.largeMobileUp]: {
            gridTemplateColumns: '1fr'
        },
        [theme.mediaQuery.largeMobileUp]: {
            gridTemplateColumns: 'repeat(2, 1fr)'
        },
        [theme.mediaQuery.tabletLandscapeUp]: {
            gridTemplateColumns: 'repeat(3, 1fr)'
        },
        [theme.mediaQuery.desktopUp]: {
            gridTemplateColumns: 'repeat(3, 1fr)'
        }
    };

    const helpButtonStyle = {
        backgroundColor: 'transparent',
        border: '2px solid white',
        '&:hover': {
            cursor: 'pointer',
            background: `${theme.colors.primary.main} !important`,
            color: theme.colors.primary.dark
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <InfoContainer>
                <Hero
                    mainImage={RES.images.hero}
                    secondaryImage={false}
                    title={STRINGS.homePage.headline}
                    subtitle={STRINGS.homePage.subtext}
                    portrait={RES.images.portrait}
                    video={RES.images.video}
                    linkUrl={RES.externalLinks.store}
                    button
                    buttonCallback={
                        isReleased()
                            ? () => history(ROUTES.buypacks)
                            : () => setShowFormModal(true)
                    }
                />
                <Container direction='column' gap={theme.spacing.xl}>
                    <Container
                        direction='column'
                        gap={theme.spacing.s}
                        padding={theme.spacing.m}
                    >
                        <UpcomingHeadline>
                            {STRINGS.homePage.variantsTitle}
                        </UpcomingHeadline>
                        <UpcomingSubtext>{STRINGS.homePage.variants}</UpcomingSubtext>
                    </Container>
                    <Grid items={RES.samples} style={CustomGrid} />
                    <Container direction='column' gap={theme.spacing.s}>
                        <UpcomingHeadline>Pack Sales</UpcomingHeadline>
                        <Container
                            gap={theme.spacing.xxl}
                            padding={theme.spacing.l}
                            wrap='wrap'
                            direction='row'
                        >
                            {RES.packs.map((pack, index) => (
                                <Container
                                    key={index}
                                    direction='column'
                                    gap={theme.spacing.l}
                                    style={{ maxWidth: '400px' }}
                                >
                                    <Image
                                        src={`${IPFS_ENDPOINT}${pack.srcImg}`}
                                        alt={pack.caption}
                                    />
                                    <Container direction='column'>
                                        <PackDescription style={{ fontSize: '22px' }}>
                                            {pack.description}
                                        </PackDescription>
                                        <PackDescription style={{ textTransform: 'uppercase' }}>
                                            {pack.quantity}
                                        </PackDescription>
                                    </Container>
                                </Container>
                            ))}
                        </Container>
                        <Container
                            direction='column'
                            alignItems='center'
                            style={{ maxWidth: '600px' }}
                        >
                            <PackDetailsTitle>Pack Probabilities</PackDetailsTitle>
                            <PackDetailsList>
                                {STRINGS.probabilities[0].probabilities.map((line, index) => (
                                    <PackDetailItem key={index}>
                                        <span>
                                            {line.rarity}{' '}
                                            {line.note ? (
                                                <span
                                                    style={{
                                                        ...theme.typography.pTiny,
                                                        marginRight: theme.spacing.xxs
                                                    }}
                                                >
                                                    {' '}
                          ({line.note}){' '}
                                                </span>
                                            ) : null}
                                        </span>
                                        <span>{line.probability}</span>
                                    </PackDetailItem>
                                ))}
                            </PackDetailsList>
                        </Container>
                    </Container>
                    <BlogPost
                        href={RES.externalLinks.blog.link}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {RES.externalLinks.blog.label}
                    </BlogPost>
                </Container>
                {!SHOW_BUY_PAGE && WAX_SIGN_IN ? (
                    <Container
                        direction='column'
                        padding={theme.spacing.l}
                        margin={`${theme.spacing.l} 0`}
                        gap={theme.spacing.m}
                        style={{
                            backgroundColor: theme.colors.grey.main,
                            color: theme.colors.primary.dark
                        }}
                    >
                        <Headline>{STRINGS.howToBuyHeadline}</Headline>
                        <HowToBuy />
                    </Container>
                ) : null}
                <Container padding={theme.spacing.s}>
                    <Button
                        style={helpButtonStyle}
                        onClick={() => setShowHelpModal(true)}
                    >
                        {STRINGS.help}
                    </Button>
                </Container>
            </InfoContainer>
            <StyledFormModal
                show={showFormModal}
                onClose={() => setShowFormModal(false)}
                title={STRINGS.formTitle}
                portalId='19612576'
                formId='c61351e2-b65a-451f-9003-f8d5d6d187cd'
            />
            <StyledFormModal
                show={showHelpModal}
                onClose={() => setShowHelpModal(false)}
                title={STRINGS.helpFormTitle}
                portalId='19612576'
                formId='6a9f8c33-f4fb-43fd-8b15-4fd2c0561566'
            />
        </>
    );
}

export default Home;
