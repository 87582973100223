import { getAccountInfo } from '@api/api';
import Container from '@components/Container';
import Dropdown from '@components/Dropdown';
import StyledLink from '@components/Link';
import styled from '@emotion/styled';
import { useAccount } from '@hooks/AccountContext';
import { FIAT_PAYMENT_ENABLED } from '@utils/globals';
import ROUTES from '@utils/routes';
import STRINGS from '@utils/strings';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { withUAL } from 'ual-reactjs-renderer';

const Funds = styled.p(({ theme }) => ({
    ...theme.typography.pTiny,
    color: theme.colors.primary.main,
    marginBottom: 0,
    textAlign: 'center'
}));

function LoginButton({ ual }) {
    const history = useNavigate();

    const { accountInformation, setAccountInformation } = useAccount();

    useEffect(() => {
        if (ual.activeUser) {
            updateAccountInfo();
        }
    }, []);

    const getDropdownOptions = () => {
        let options = [
            {
                label: STRINGS.resources,
                onClick: () => history(ROUTES.resources)
            },
            {
                label: STRINGS.logOut,
                onClick: () => {
                    ual.logout();
                    history(ROUTES.home);
                }
            }
        ];
        if (FIAT_PAYMENT_ENABLED) {
            options = [
                {
                    label: STRINGS.fiatPurchases,
                    onClick: () => history(ROUTES.paymenthistory)
                },
                ...options
            ];
        }
        return options;
    };

    const updateAccountInfo = () =>
        getAccountInfo(ual.activeUser, setAccountInformation);

    return !ual.activeUser ? (
        <StyledLink header onClick={ual.showModal}>
            {STRINGS.logIn}
        </StyledLink>
    ) : (
        <Container direction='column' alignItems='center' justifyContent='center'>
            <Dropdown
                label={ual.activeUser.accountName}
                options={getDropdownOptions()}
            />
            {accountInformation ? (
                <Funds>{accountInformation.waxAvailable}</Funds>
            ) : null}
        </Container>
    );
}

export default withUAL(LoginButton);
