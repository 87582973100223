import StyledButton from '@components/Button';
import styled from '@emotion/styled';
import { SHOW_BUY_PAGE } from '@utils/globals';
import ROUTES from '@utils/routes';
import STRINGS from '@utils/strings';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { withUAL } from 'ual-reactjs-renderer';

const Container = styled.div(() => ({
    textAlign: 'left'
}));

const StepNumber = styled.div(({ theme }) => ({
    ...theme.typography.forum,
    display: 'flex',
    flex: 'none',
    borderRadius: '50%',
    background: '#000000',
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '1.7rem',
    textAlign: 'center',
    width: '80px',
    height: '80px',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.mediaQuery.mobileOnly]: {
        alignSelf: 'center'
    }
}));

const StepContainer = styled.div(() => ({
    display: 'block'
}));

const Title = styled.h2(({ theme }) => ({
    ...theme.typography.h2,
    ...theme.typography.forum,
    fontWeight: 'bold',
    color: theme.colors.primary.dark,
    marginBottom: theme.spacing.s
}));

const Description = styled.p(({ theme }) => ({
    fontSize: '1rem',
    lineHeight: '180%',
    marginBottom: '1rem',
    color: theme.colors.common.lightBackground
}));

const ButtonsContainer = styled.div(({ theme }) => ({
    gap: theme.spacing.xs
}));

const ContentContainer = styled.div(({ theme, mainImage, portrait }) => ({
    display: 'flex',
    maxWidth: '120ch',
    margin: '0 auto',
    padding: theme.spacing.xs,
    gap: theme.spacing.l,
    [theme.mediaQuery.mobileOnly]: {
        flexDirection: 'column'
    },
    [theme.mediaQuery.largeMobileOnly]: {
        textAlign: 'center'
    },
    [theme.mediaQuery.tabletLandscapeUp]: {}
}));

function HowToBuy({ ual }) {
    const history = useNavigate();
    const toShop = () => history(`${ROUTES.buypacks}`);
    const toInventory = () => history(`${ROUTES.inventory}`);
    const toAllAccess = () => window.open('https://all-access.wax.io');

    return (
        <Container>
            <ContentContainer>
                <StepNumber>1</StepNumber>
                <StepContainer>
                    <Title>{STRINGS.howToBuy.createWaxWallet}</Title>
                    <Description>{STRINGS.howToBuy.waxWalletDescription}</Description>
                    <ButtonsContainer>
                        <StyledButton onClick={toAllAccess}>
                            {STRINGS.howToBuy.waxWalletButton}
                        </StyledButton>
                    </ButtonsContainer>
                </StepContainer>
            </ContentContainer>
            <ContentContainer>
                <StepNumber>2</StepNumber>
                <StepContainer>
                    <Title>{STRINGS.howToBuy.waxWalletLogin}</Title>
                    <Description>
                        {STRINGS.howToBuy.waxWalletLoginDescription}
                    </Description>
                    <ButtonsContainer>
                        <StyledButton
                            disabled={ual.activeUser ? true : false}
                            onClick={ual.showModal}
                        >
                            {ual.activeUser
                                ? STRINGS.howToBuy.waxWalletLoginButton
                                : STRINGS.logIn}
                        </StyledButton>
                    </ButtonsContainer>
                </StepContainer>
            </ContentContainer>
            <ContentContainer>
                <StepNumber>3</StepNumber>
                <StepContainer>
                    <Title>{STRINGS.howToBuy.packs}</Title>
                    <Description>{STRINGS.howToBuy.packsDescription}</Description>
                    <ButtonsContainer>
                        <StyledButton disabled={!SHOW_BUY_PAGE} onClick={toShop}>
                            {SHOW_BUY_PAGE ? STRINGS.buyPacks : STRINGS.shopPageSoon}
                        </StyledButton>
                    </ButtonsContainer>
                </StepContainer>
            </ContentContainer>
            <ContentContainer>
                <StepNumber>4</StepNumber>
                <StepContainer>
                    <Title>{STRINGS.howToBuy.openPacks}</Title>
                    <Description>{STRINGS.howToBuy.openPacksDescription}</Description>
                    <ButtonsContainer>
                        <StyledButton
                            disabled={ual.activeUser ? false : true}
                            onClick={toInventory}
                        >
                            {STRINGS.howToBuy.openPacks}
                        </StyledButton>
                    </ButtonsContainer>
                </StepContainer>
            </ContentContainer>
        </Container>
    );
}

export default withUAL(HowToBuy);
